import React from 'react';
import DOMPurify from "isomorphic-dompurify";
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../../../elements/richText';
import arrowIcon from '../../../../../static/images/scholarship/arrow-down.png';

const Container = styled.div`
  border-top: 1px solid #e9e9e9;
  padding: 60px;
  max-width: 1403px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 30px 60px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 20px;
    padding-right: 20px;
  } 
`;

const TermsContainer = styled.div`
  padding-top: 40px;
  max-height: 0px;
  transition: max-height 0.35s ease, visibility 0.1s 0.35s linear;
  overflow: hidden;
  visibility:hidden;

  ${props => props.open && css`
    transition: max-height 0.75s ease-in;
    max-height: 20000px;
    visibility: visible;
  `}
`;

const Toggle = styled.a`
  display: inline-block;
  padding-right: 35px !important;
  background-image: url(${arrowIcon});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  color: #6785ff;
  transition: 0.35s ease;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colours.green}; 
  }

  &:focus {
    background-image: url(${arrowIcon});
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
`;

const TermsTable = styled.div`
  table {
    height: auto !important;
    border-collapse: collapse;
    margin: 0 0 60px 0;
    border: 1px solid #f7f8f9;
  }

  tr {
    height: auto !important;
    &:nth-of-type(odd) {
      background: #f7f8f9;
    }
  }

  td {
    vertical-align: top;
    border: 0;
    box-sizing: border-box;

    &:first-of-type {
      font-size: 15px;
      line-height: 25px;
      color: #051033;
      font-family: ${props => props.theme.font.nimbleBoldFont};
      font-weight: 700;
      width: 35% !important;
      height: auto !important;
      padding: 15px 15px 15px 35px;
    }

    &:last-of-type {
      font-size: 14px;
      line-height: 24px;
      width: 65% !important;
      height: auto !important;
      padding: 15px 35px 15px 15px;
    }

    @media(max-width: ${props => props.theme.breakpoints.max.md}){
      display: block;
      width: 100% !important;
      &:first-of-type {
        padding: 30px 20px 7px;
      }

      &:last-of-type {
        padding: 0 20px 30px;
      }

    }

    ul, ol {
      padding-left: 20px;

      li {
        font-size: 14px;
        line-height: 1.75;
        padding-left: 4px;
      }
    }
  }
`;

const TermsList = styled.div`   
  h6 {
    text-align: left;    
    font-size: 16px;
    color: ${props => props.theme.colours.slate};
    padding: 0;
    margin: 0;
  }

  & > p {
    font-family: ${props => props.theme.font.nimbleBoldFont};
    font-size: 20px;
    margin-top: 45px !important;
  }

  ul {
    margin-left: 50px;
    color: #232c49;

    li {
      margin: 0 0 25px 0;
      list-style-type: lower-latin;

      p {
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 10px;
      }

      &:last-of-type {
        margin: 0;
      }

      ul {
        margin: 25px 0 0 50px;

        li {
          list-style-type: lower-roman;
        }
      }
    }
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    ul {
      margin-left: 30px;

      li {
        padding-left: 7px;

        ul {
          margin-left: 35px;
        }
      }
    }
  }

`;

const Terms = ({ sectionData, termsOpen, setTermsOpen }) => {
  const {
    title,
    components
  } = sectionData;

  const termsText1Data = components.filter(
    data => data.componentId === 'Scholarship - Terms and conditions - Text 1'
  );

  const termsText2Data = components.filter(
    data => data.componentId === 'Scholarship - Terms and conditions - Text 2'
  );

  const handleTermsToggle = (e) => {
    e.preventDefault();
    setTermsOpen(!termsOpen);
  };

  return (
    <Container>
      <p className="center">Terms and Conditions</p>
      <p className="center">
        For Terms and Conditions,&nbsp;
        <Toggle href="#terms-holder" onClick={(e) => handleTermsToggle(e)}>click here</Toggle>
      </p>
      <TermsContainer id="terms-holder" open={termsOpen}>
        <h2>{title}</h2>
        {termsText1Data.length && termsText1Data[0].html
          && <TermsTable dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(termsText1Data[0].html.html) }} />}
        {termsText2Data.length && termsText2Data[0].text
          && (
            <TermsList><RichText text={termsText2Data[0].text} /></TermsList>
          )}
      </TermsContainer>
    </Container>
  );
};

Terms.propTypes = {
  sectionData: PropTypes.shape({
    title: PropTypes.string,
    descriptionRichText: PropTypes.shape({
      childContentfulRichText: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    components: PropTypes.arrayOf(PropTypes.object)
  }),
  termsOpen: PropTypes.bool,
  setTermsOpen: PropTypes.func
};

export default Terms;
