import React from 'react';
import DOMPurify from "isomorphic-dompurify";
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import checkImage from '../../../../../static/images/scholarship/check.png';

const Container = styled.div`
  padding: 120px 20px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 90px 20px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 60px 20px;
  }  

  ${props => props.background === 'Grey' && css`
    background: #f7f8f9;
  `}
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    margin-bottom: 45px;
  }  

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    margin-bottom: 30px;
  }  
`;

const Content = styled.div`
  max-width: 1403px;
  margin: auto;
  padding-left: 60px;
  padding-right: 60px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding-left: 40px;
    padding-right: 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding-left: 0;
    padding-right: 0;
  }  
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    display: block;
  }
`;

const ImageContainer = styled.div`
  flex: 0 0 50%;
  order: 1;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    flex: 0 0 45%;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    margin-bottom: 30px;
  }

  ${props => props.imagePosition === 'Right' && css`
    order: 3;
  `}
`;

const Image = styled.img`
  width: 100%;
`;

const Text = styled.div`
  flex: 0 0 42%;
  order: 2;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    flex: 0 0 50%;
  }

  & > ul {
    list-style: none;
    & > li {
      font-size: 18px;
      margin-bottom: 15px;
      margin-left: 0;
      padding-bottom: 15px;
      padding-left: 45px;
      border-bottom: 1px solid #e2e8ff;
      background-image: url(${checkImage});
      background-position: 15px 9px;
      background-repeat: no-repeat;

      li {
        padding-left: 10px;
      }
    }
  }
`;

const TextAndImage = ({ basicData }) => {
  const {
    basicHeading,
    componentBackground,
    floatedImage,
    floatedImagePosition,
    html
  } = basicData;

  return (
    <Container background={componentBackground}>
      <Content>
        {basicHeading && <Title>{basicHeading}</Title>}
        <Row>
          <ImageContainer imagePosition={floatedImagePosition}>
            <Image src={floatedImage.fluid.src} alt="" />
          </ImageContainer>
          <Text dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html.html) }} />
        </Row>
      </Content>
    </Container>
  );
};

TextAndImage.propTypes = {
  basicData: PropTypes.shape({
    basicHeading: PropTypes.string,
    componentBackground: PropTypes.string,
    floatedImage: PropTypes.shape({
      fluid: PropTypes.shape({
        src: PropTypes.string
      })
    }),
    floatedImagePosition: PropTypes.string,
    html: PropTypes.shape({
      html: PropTypes.string
    })
  })
};

export default TextAndImage;
